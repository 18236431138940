import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { useDispatch, useSelector } from "react-redux";
import { userSelector, logoutUser } from "../../features/userSlice";

import logo from "../../assets/logo.svg";

import HeaderAuthenticated from "./HeaderAuthenticated";
import HeaderUnAuthenticated from "./HeaderUnAuthenticated";

const Wrapper = styled.header`
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 10;
`;

const NavContainer = styled.div`
  position: relative;
  max-width: 1340px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 10px 0px;

  &:before {
    content: "";
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
  }
`;

const LoginButton = styled.a`
  display: flex;
  height: 40px;
  color: #fff;
  background-color: #5f3aad;
  text-decoration: none;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    color: #fff;
  }
`;

const LoginButtonIconWrapper = styled.span`
  display: flex;
  background-color: #56349c;
  color: #fff;
  height: 100%;
  align-items: center;
  padding: 0 10px;
`;

const LoginButtonTextWrapper = styled.span`
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
`;

const Header = () => {
  const { user } = useSelector(userSelector);

  return (
    <Wrapper>
      <NavContainer>
        <Navbar expand="lg" className="" variant="dark">
          <Container>
            <Navbar.Brand href="#home">
              <img
                alt=""
                width="220"
                src={logo}
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {user ? <HeaderAuthenticated /> : <HeaderUnAuthenticated />}
          </Container>
        </Navbar>
      </NavContainer>
    </Wrapper>
  );
};

export default Header;
