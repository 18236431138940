import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import { cibTwitch } from "@coreui/icons";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const { REACT_APP_API } = process.env;

const LoginButton = styled.a`
  display: flex;
  height: 40px;
  color: #fff;
  background-color: #5f3aad;
  text-decoration: none;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    color: #fff;
  }

  cursor: pointer;
`;

const LoginButtonIconWrapper = styled.span`
  display: flex;
  background-color: #56349c;
  color: #fff;
  height: 100%;
  // align-items: center;
  padding: 0 10px;
  width: 35px;
  color: #fff;
`;

const LoginButtonTextWrapper = styled.span`
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
`;

const HeaderUnAuthenticated = () => {
  return (
    <>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="#tracker">Tracker</Nav.Link>
          <Nav.Link href="#overlay">Widget</Nav.Link>
          <Nav.Link href="#chatbot">Chatbot</Nav.Link>
          <Nav.Link href="#customizable">Anpassbar</Nav.Link>
          <Nav.Link href="#program">Das Programm</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <LoginButton href={`${REACT_APP_API}/oauth/twitch`}>
          <LoginButtonIconWrapper>
            <CIcon icon={cibTwitch} size="lg" />
          </LoginButtonIconWrapper>
          <LoginButtonTextWrapper>Login with Twitch</LoginButtonTextWrapper>
        </LoginButton>
      </Navbar.Collapse>
    </>
  );
};

export default HeaderUnAuthenticated;
