import styled from "styled-components";
import Image from "react-bootstrap/Image";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import React from "react";
import { LinkContainer } from "react-router-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { userSelector, logoutUser } from "../../features/userSlice";

const LoginButton = styled.a`
  display: flex;
  height: 40px;
  color: #fff;
  background-color: #5f3aad;
  text-decoration: none;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    color: #fff;
  }
`;

const LoginButtonIconWrapper = styled.span`
  display: flex;
  background-color: #56349c;
  color: #fff;
  height: 100%;
  align-items: center;
  padding: 0 10px;
`;

const LoginButtonTextWrapper = styled.span`
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
`;

const ButtonClean = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
`;

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <ButtonClean
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </ButtonClean>
));

const HeaderAuthenticated = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);

  return (
    <>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <LinkContainer to="/stats">
            <Nav.Link>Statistik</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/subscriptions">
            <Nav.Link>Abonnements</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/overlay">
            <Nav.Link>Overlay</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/settings">
            <Nav.Link>Einstellungen</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/help">
            <Nav.Link>Hilfe</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <Image
              width={45}
              src={user.twitch.profilePictureUrl}
              roundedCircle
            />
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">
            <Dropdown.Item onClick={() => dispatch(logoutUser())}>
              Abmelden
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Collapse>
    </>
  );
};

export default HeaderAuthenticated;
