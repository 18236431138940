import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  border-top: 1px solid #6441a5;
  margin-top: 50px;
  padding: 20px 0;
  color: #fff;
  font-size: 12px;
`;

const Footer = () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <FooterWrapper>
            Developed with ❤️ in Germany by StreamRTech
          </FooterWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
