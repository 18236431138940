import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Header from "./components/Header";
import Footer from "./components/Footer";

import React, { useEffect } from "react";

import { userSelector, reauthenticateUser } from "./features/userSlice";
import styled from "styled-components";

import Alert from "react-bootstrap/Alert";

const HomePage = React.lazy(() => import("./pages/Home"));
const StatsPage = React.lazy(() => import("./pages/Stats"));
const SubscriptionsPage = React.lazy(() => import("./pages/Subscriptions"));
const OverlayPage = React.lazy(() => import("./pages/Overlay"));
const SettingsPage = React.lazy(() => import("./pages/Settings"));
const HelpPage = React.lazy(() => import("./pages/Help"));
const NotAffiliatePage = React.lazy(() => import("./pages/NotAffiliate"));
const AdminPage = React.lazy(() => import("./pages/Admin"));

const Wrapper = styled.div`
  padding-top: 200px;
`;

function App() {
  const dispatch = useDispatch();
  const { reauthenticated, user } = useSelector(userSelector);

  useEffect(() => {
    console.log("try to reauth");
    dispatch(reauthenticateUser());
  }, [dispatch]);

  if (!reauthenticated) {
    return null;
  }

  return (
    <>
      <Header />
      <Wrapper>
        <Container>
          <Row>
            <Col xs={12}>
              <Alert variant="info">
                Diese Anwendung befindet sich im Beta-Test, bitte tretet unserem
                Discord Server bei, damit wir euch bei Änderungen oder wichtigen
                Hinweisen zu dem Tool oder dessen Benutzung informieren können:{" "}
                <a
                  href="https://discord.gg/cWpHey4rsp"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://discord.gg/cWpHey4rsp
                </a>
              </Alert>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
        <Routes>
          {user ? (
            user.twitch.subCount && user.twitch.subCount >= 40 ? (
              <>
                <Route path="/stats" element={<StatsPage />} />
                <Route path="/subscriptions" element={<SubscriptionsPage />} />
                <Route path="/overlay" element={<OverlayPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/help" element={<HelpPage />} />
                {user.admin && <Route path="/admin" element={<AdminPage />} />}
                <Route path="*" element={<Navigate to="/stats"></Navigate>} />
              </>
            ) : (
              <Route path="*" element={<NotAffiliatePage />} />
            )
          ) : (
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="*" element={<Navigate to="/"></Navigate>} />
            </>
          )}
        </Routes>
        <Footer />
      </Wrapper>
    </>
  );
}

export default App;
